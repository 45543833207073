import { VersionActionTypeEnum } from '../../enums/actions';
import {
  VersionActionTypes,
  VersionReducerState,
} from '../../types/versionStore';

export const initialState: VersionReducerState = {};

export const versionReducer = (
  state = initialState,
  action: VersionActionTypes,
): VersionReducerState => {
  switch (action.type) {
    case VersionActionTypeEnum.READ_VERSION_SUCCESS:
      return {
        ...state,
        versionList: { ...action.meta, ...action.payload },
      };
    default:
      return state;
  }
};
