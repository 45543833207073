import { NotificationActionTypeEnum } from '../../enums/actions';
import {
  NotificationActionTypes,
  NotificationReducerState,
} from '../../types/notificationStore';

export const initialState: NotificationReducerState = {
  notifications: [],
};

export const notificationReducer = (
  state = initialState,
  action: NotificationActionTypes,
): NotificationReducerState => {
  switch (action.type) {
    case NotificationActionTypeEnum.READ_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notifications: action.payload,
      };
    case NotificationActionTypeEnum.MASK_AS_READ_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notification: action.payload,
      };
    default:
      return state;
  }
};
