import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import AppProviders from './components/app/AppProviders';
import App from './components/app';
import * as serviceWorker from './serviceWorker';

// Perf monitoring
if (process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires, global-require
  const whyDidYouRender = require('@welldone-software/why-did-you-render/dist/no-classes-transpile/umd/whyDidYouRender.min.js');
  whyDidYouRender(React);
}

ReactGA.initialize(process.env.REACT_APP_GA_APP_ID || '', {
  debug: process.env.NODE_ENV !== 'production',
});
ReactDOM.render(
  <AppProviders>
    <App />
  </AppProviders>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
