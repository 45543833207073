import { SecureStorageEnum } from '../enums/auth';

const setItem = (name: SecureStorageEnum, value: string) => {
  return sessionStorage.setItem(name, value);
};

const getItem = (name: SecureStorageEnum) => {
  return sessionStorage.getItem(name);
};

const removeItem = (name: SecureStorageEnum) => {
  return sessionStorage.removeItem(name);
};

export default {
  setItem,
  getItem,
  removeItem,
};
