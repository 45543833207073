import { LoginActionTypeEnum } from 'enums/actions';
import { LoginActionTypes, LoginReducerState } from 'types/loginStore';

export const initialState: LoginReducerState = {
  status: false,
  user: undefined,
};

export const loginReducer = (
  state = initialState,
  action: LoginActionTypes,
): LoginReducerState => {
  switch (action.type) {
    case LoginActionTypeEnum.SET_LOGIN:
      return {
        ...state,
        status: true,
      };
    case LoginActionTypeEnum.SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    default:
      return state;
  }
};
