import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { get } from 'lodash';
import configureStore from 'store';
import { Provider } from 'react-redux';
import ThemeProvider from '../../styles/ThemeProvider';

function getUserConfirmation(
  dialogKey: string,
  callback: (ok: boolean) => void,
): void {
  // use "message" as Symbol-based key
  const dialogTrigger: (cb: (ok: boolean) => void) => void | undefined = get(
    window,
    Symbol.for(dialogKey),
  );
  if (dialogTrigger) {
    // pass the callback to delegate
    // to the invoked dialog
    return dialogTrigger(callback);
  }
  // Fallback to allowing navigation
  return callback(true);
}

export interface AppProvidersProps {
  children: React.ReactNode;
}

const store = configureStore();

function AppProviders({ children }: AppProvidersProps) {
  return (
    <Provider store={store}>
      <BrowserRouter getUserConfirmation={getUserConfirmation}>
        <ThemeProvider>{children}</ThemeProvider>
      </BrowserRouter>
    </Provider>
  );
}

export default AppProviders;
