import {
  createStore,
  applyMiddleware,
  compose,
  combineReducers,
  AnyAction,
} from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import { RootStateType } from 'types/store';
import { LoginActionTypeEnum } from 'enums/actions';
import {
  initialState as initialTaskState,
  asyncTaskReducer,
} from './reducers/asyncTaskReducer';
import {
  globalSearchReducer,
  initialState as initialGlobalSearchState,
} from './reducers/globalSearchReducer';

import {
  purchaseOrderReducer,
  initialState as initialPurchaseOrderState,
} from './reducers/purchaseOrderReducer';

import {
  snackBarReducer,
  initialState as initialSnackBarState,
} from './reducers/snackBarReducer';

import {
  notificationReducer,
  initialState as initialNotificationState,
} from './reducers/notification';

import {
  loginReducer,
  initialState as initialLoginState,
} from './reducers/loginReducer';

import {
  dashboardReducer,
  initialState as initialDashboardState,
} from './reducers/dashboardReducer';

import {
  versionReducer,
  initialState as initialVersionReducer,
} from './reducers/versionReducer';

export const initialRootState: RootStateType = {
  loginReducer: initialLoginState,
  snackBarReducer: initialSnackBarState,
  asyncTaskReducer: initialTaskState,
  globalSearchReducer: initialGlobalSearchState,
  purchaseOrderReducer: initialPurchaseOrderState,
  notificationReducer: initialNotificationState,
  dashboardReducer: initialDashboardState,
  versionReducer: initialVersionReducer,
};

export default function configureStore(
  preloadedState: RootStateType = initialRootState,
) {
  const middlewares = [thunk];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer];
  const composedEnhancers =
    process.env.NODE_ENV === 'development'
      ? composeWithDevTools(...enhancers)
      : compose(...enhancers);

  const appReducer = combineReducers<RootStateType>({
    loginReducer,
    snackBarReducer,
    asyncTaskReducer,
    globalSearchReducer,
    purchaseOrderReducer,
    notificationReducer,
    dashboardReducer,
    versionReducer,
  });

  // Reset state after logout
  const rootReducer = (state: RootStateType, action: AnyAction) => {
    return action.type === LoginActionTypeEnum.LOGOUT
      ? initialRootState
      : appReducer(state, action);
  };

  // @ts-ignore
  return createStore(rootReducer, preloadedState, composedEnhancers);
}
