import { DashboardReducerState } from '../../types/dashboardStore';

export const initialState: DashboardReducerState = {
  totalWarehouse: {
    data: [
      { name: 'POs to be tagged', total: 0 },
      { name: 'Number of line to be tagged', total: 0 },
      { name: 'Partiallu tagged POs', total: 0 },
      { name: 'POs pending checkin', total: 0 },
    ],
  },
  totalVessel: {
    data: [{ name: 'POs arrived on vessel', total: 0 }],
  },
  pendingCheckin: [],
};

export const dashboardReducer = (
  state = initialState,
  action: any,
): DashboardReducerState => {
  switch (action.type) {
    default:
      return state;
  }
};
