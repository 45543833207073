import { createMuiTheme, ThemeOptions } from '@material-ui/core/styles';
import { ThemeColors } from '../types/style';
// Module augmentation for Typescript to support the following custom properties
declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    colors: ThemeColors;
  }
  interface ThemeOptions {
    colors: ThemeColors;
  }
}

const defaultTheme = createMuiTheme();

// For new colors, add object key to 'IColors' interface
const colors = {
  navyBlue: '#142D58',
  navyBlue2: '#294067',
  navyBlue3: '#495D7E',
  navyBlue4: '#556887',
  navyBlue5: '#1976D2',
  brightBlue: '#2D7DFE',
  brightBlue2: '#EAF2FF',
  brightBlueOverlay: 'rgba(45, 125, 254, 0.1)',
  lightBlue: '#E3E8EC',
  fieryRed: '#FF003F',
  fieryRed2: '#FFDEE3',
  fieryRed3: '#FFF1F3',
  happyGreen: '#03BD83',
  happyGreen2: '#026646',
  happyGreen3: '#95E5CC',
  longingOrange: '#FFA401',
  longingOrange2: '#8C5A01',
  longingOrange3: '#FFDB99',
  alluringPurple: '#BF006E',
  alluringPurple2: '#66003B',
  alluringPurple3: '#F2CEE3',
  chicGold: '#AF9952',
  chicGold2: '#665930',
  chicGold3: '#D9D1B8',
  tranquilTeal: '#00ACAD',
  tranquilTeal2: '#007F80',
  tranquilTeal3: '#A3D8D9',
  darkBlack: '#222222',
  darkBlackBackDrop: 'rgba(34,34,34,0.7)',
  gray1: '#666666',
  gray2: '#999999',
  gray3: '#DDDDDD',
  gray4: '#F5F5F5',
  pureWhite: '#FFFFFF',
  halfTransparentWhite: 'rgba(255, 255, 255, 0.5)',
  gold: '#AF9952',
};

const defaultFontFamily = 'Heebo, Roboto, Arial, sans-serif';

/**
 * DEFAULT_THEME_CONFIG
 * Set global and re-usable style for project
 * Set default style for all MUI components
 * Allow overriding MUI default styles and props
 */
const DEFAULT_THEME_CONFIG: ThemeOptions = {
  colors,
  breakpoints: {
    values: {
      xs: 0,
      sm: 992,
      md: 1200,
      lg: 1440,
      xl: 1600,
    },
  },
  palette: {
    background: {
      default: colors.pureWhite,
    },
    primary: {
      main: colors.navyBlue,
    },
    secondary: {
      main: colors.brightBlue,
      light: colors.brightBlue,
      dark: colors.brightBlue,
    },
    error: {
      main: colors.fieryRed,
    },
    warning: {
      main: colors.longingOrange,
    },
    info: {
      main: colors.brightBlue,
    },
    success: {
      main: colors.happyGreen,
    },
    text: {
      disabled: colors.gray2,
      hint: colors.gray1,
      primary: colors.darkBlack,
      secondary: colors.gray2,
    },
  },
  spacing: 8,
  typography: {
    fontFamily: defaultFontFamily,
    fontSize: 14,
    fontWeightBold: 700,
    fontWeightMedium: 500,
    fontWeightLight: 300,
    h1: {
      fontFamily: defaultFontFamily,
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 40,
      lineHeight: '64px',
      color: colors.darkBlack,
      marginBottom: 32,
    },
    h2: {
      fontFamily: defaultFontFamily,
      fontSize: '3.75rem',
      fontWeight: 300,
      letterSpacing: 0,
      lineHeight: 1.2,
    },
    h3: {
      fontFamily: defaultFontFamily,
      fontSize: '3rem',
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: 1.167,
    },
    h4: {
      fontFamily: defaultFontFamily,
      fontSize: '2.125rem',
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: 1.235,
    },
    h5: {
      fontFamily: defaultFontFamily,
      fontSize: '1.5rem',
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: 1.334,
    },
    h6: {
      fontFamily: defaultFontFamily,
      fontSize: '1.25rem',
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: 1.6,
    },
    subtitle1: {
      fontFamily: defaultFontFamily,
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 32,
      lineHeight: '56px',
      color: colors.darkBlack,
    },
    subtitle2: {
      fontFamily: defaultFontFamily,
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 20,
      lineHeight: '32px',
      color: colors.darkBlack,
    },
    body1: {
      fontFamily: defaultFontFamily,
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '24px',
      color: colors.darkBlack,
    },
    body2: {
      fontFamily: defaultFontFamily,
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '24px',
      color: colors.darkBlack,
    },
    button: {
      fontFamily: defaultFontFamily,
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '24px',
      color: colors.darkBlack,
    },
    caption: {
      fontFamily: defaultFontFamily,
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 12,
      lineHeight: '16px',
      color: colors.darkBlack,
    },
  },
  zIndex: {
    mobileStepper: 1000,
    speedDial: 1050,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
  },
  // Override MUI components' default style
  overrides: {
    MuiFormControl: {
      marginNormal: {
        marginTop: 0,
        marginBottom: 24,
      },
    },
    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    MuiMenuItem: {
      root: {
        padding: 8,
        paddingTop: 8,
        paddingBottom: 8,
      },
    },
    MuiTableRow: {
      root: {
        cursor: 'pointer',
      },
    },
    MuiToolbar: {
      gutters: {
        paddingLeft: 16,
        paddingRight: 16,
        [defaultTheme.breakpoints.up('sm')]: {
          paddingLeft: 24,
          paddingRight: 24,
        },
      },
    },
    MuiTypography: {
      gutterBottom: {
        marginBottom: 16,
      },
    },
    MuiRadio: {
      root: {
        padding: '0px 8px 0px 0px',
        '&$checked': {
          color: colors.brightBlue,
        },
      },
    },

    MuiListItem: {
      root: {
        '&$selected': {
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: colors.brightBlue2,
          },
        },
        '&$focusVisible': {
          backgroundColor: colors.pureWhite,
        },
      },
      button: {
        '&:hover': {
          backgroundColor: colors.brightBlue2,
        },
      },
    },
    MuiCssBaseline: {
      '@global': {
        '*::-webkit-scrollbar': {
          width: '4px',
          height: '4px',
        },
        '*::-webkit-scrollbar-track': {
          background: colors.gray3,
          borderRadius: '4px',
        },
        '*::-webkit-scrollbar-thumb': {
          background: colors.gray2,
          borderRadius: '4px',
        },
      },
    },
    MuiTableCell: {
      root: {
        padding: '16px 8px',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: 'normal',
        borderBottom: `1px solid ${colors.gray3}`,
        '&:first-child': {
          paddingLeft: '16px',
        },
        '&:last-child': {
          paddingRight: '16px',
        },
      },
      head: {
        whiteSpace: 'nowrap',
      },
    },
    MuiButtonGroup: {
      contained: {
        boxShadow: 'none',
      },
    },
    MuiButton: {
      root: {
        minHeight: 40,
      },
      text: {
        padding: 8,
      },
      contained: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
        '&$disabled': {
          color: 'white',
        },
      },
      containedSecondary: {
        '&:hover': {
          color: '#eaf2fe',
        },
      },
      outlined: {},
      outlinedSecondary: {
        border: `1px solid ${colors.brightBlue}`,
      },
    },
    MuiInputBase: {
      input: {
        padding: 8,
        fontSize: 14,
        height: 24,
      },
      root: {
        background: colors.pureWhite,
        borderRadius: 4,
        '&$disabled': {
          backgroundColor: '#F5F5F5',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        minHeight: 40,
        '&$focused $input': {
          caretColor: colors.brightBlue,
        },
        '&$focused $notchedOutline': {
          borderColor: colors.brightBlue,
          borderWidth: 1,
        },
        '&$error': {
          backgroundColor: colors.fieryRed2,
          '& $input': {
            caretColor: colors.fieryRed,
          },
        },
        '&$error $notchedOutline': {
          borderColor: colors.fieryRed,
        },
      },
      adornedEnd: {
        paddingRight: 8,
      },
      input: {
        padding: 8,
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    MuiInputAdornment: {
      root: {
        color: colors.gray2,
      },
    },
    MuiSlider: {
      root: {
        color: colors.brightBlue,
        '& $thumb $valueLabel': {
          color: 'transparent',
          transform: 'unset',
        },
      },
      rail: {
        opacity: 1,
        backgroundColor: colors.gray3,
      },
      thumb: {
        width: 32,
        height: 32,
        marginTop: -16,
        marginLeft: -16,
        boxShadow:
          '0px 0px 6px rgba(0, 0, 0, 0.06), 0px 4px 8px rgba(0, 0, 0, 0.08)',
        '&:hover, &$focusVisible': {
          boxShadow:
            '0px 0px 6px rgba(0, 0, 0, 0.06), 0px 4px 8px rgba(0, 0, 0, 0.08)',
        },
      },
      valueLabel: {
        position: 'static',
      },
    },
    MuiSwitch: {
      root: {
        width: 48,
        height: 32,
      },
      colorSecondary: {
        '&$checked + $track': {
          backgroundColor: colors.gray3,
        },
      },
      switchBase: {
        '&$checked': {
          transform: 'translateX(16px)',
          '& $thumb': {
            border: 'none',
          },
        },
      },
      track: {
        opacity: 1,
        backgroundColor: colors.gray2,
      },
      thumb: {
        width: 14,
        height: 14,
        border: `1px solid ${colors.gray3}`,
        boxShadow: 'none',
      },
    },
    MuiCheckbox: {
      root: {
        padding: '0px 8px 0px 0px',
      },
    },
    MuiSelect: {
      selectMenu: {
        paddingTop: 12,
        paddingBottom: 12,
        boxSizing: 'border-box',
      },
      select: {
        '&:focus': {
          backgroundColor: colors.pureWhite,
        },
      },
    },
    MuiFormLabel: {
      root: {
        lineHeight: '16px',
        fontSize: '1em',
      },
    },
    MuiTablePagination: {
      toolbar: {
        padding: '8px 16px',
        paddingRight: '16px',
      },
      caption: {
        fontSize: 14,
        lineHeight: '16px',
        fontWeight: 'normal',
      },
      selectRoot: {
        marginRight: 32,
      },
      actions: {
        '& button': {
          padding: 0,
        },
      },
    },
    MuiBreadcrumbs: {
      separator: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    MuiTableSortLabel: {
      root: {
        '&$active': {
          color: colors.gray2,
        },
      },
    },
  },
};

export const theme = createMuiTheme(DEFAULT_THEME_CONFIG);
