import { SearchPurchaseOrderTypeEnum } from 'enums/actions';
import { GlobalSearchReducerState } from 'types/globalSearchStore';

export const initialState: GlobalSearchReducerState = {
  error: null,
  globalSearchList: [],
};

export const globalSearchReducer = (
  state = initialState,
  action: any,
): GlobalSearchReducerState => {
  switch (action.type) {
    case SearchPurchaseOrderTypeEnum.READ_LIST_PURCHASE_ORDERS_SUCCESS:
      return {
        ...state,
        globalSearchList: action.payload,
      };
    default:
      return state;
  }
};
