import React, { Suspense, useEffect } from 'react';
import { lazy } from '@loadable/component';
import SnackBar from 'components/SnackBar';
import { useSelector, useDispatch } from 'react-redux';
import secureStorage from 'utils/secureStorage';
import { sAuthenticated } from 'store/selectors/selectors';
import { SecureStorageEnum } from 'enums/auth';
import CircularLoader from 'components/Loader/CircularLoader';
import { LoginActionTypeEnum } from 'enums/actions';

const AppRoutes = lazy(() => import('components/Routes/AppRoutes'));
const AuthRoutes = lazy(() => import('components/Routes/AuthRoutes'));

function App() {
  const dispatch = useDispatch();
  const isStoreAuthenticated = useSelector(sAuthenticated);
  const token = secureStorage.getItem(SecureStorageEnum.ACCESS_TOKEN);
  const isAuthenticated = token || isStoreAuthenticated;

  useEffect(() => {
    if (token) {
      dispatch({
        type: LoginActionTypeEnum.SET_LOGIN,
        payload: { accessToken: token },
      });
    }
  }, [dispatch, token]);

  const isIframe = () => {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  };

  if (isIframe()) {
    return null;
  }

  return (
    <Suspense fallback={<CircularLoader />}>
      {isAuthenticated ? <AppRoutes /> : <AuthRoutes />}
      <SnackBar />
    </Suspense>
  );
}

export default App;
