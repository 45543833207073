import React from 'react';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { StylesProvider as MuiStylesProvider } from '@material-ui/styles';
import MuiCssBaseline from '@material-ui/core/CssBaseline';
import { theme as nextMuiTheme } from './theme';

interface Props {
  children: React.ReactNode;
}

const ThemeProvider = ({ children }: Props) => {
  // By default, Material-UI's styles are injected last in the element of the page. As a result,
  // they gain more specificity than any other style sheet. If you want to override Material-UI's
  // styles, set this prop.
  return (
    <MuiStylesProvider injectFirst>
      <MuiThemeProvider theme={nextMuiTheme}>
        <MuiCssBaseline />
        {children}
      </MuiThemeProvider>
    </MuiStylesProvider>
  );
};

export default ThemeProvider;
